import { Grid2 } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { PortalLink } from 'src/portal/components/PortalLink'
import { StatusIconEnum } from 'src/portal/components/types'
import { StatusBadge } from 'src/portal/pages/home/components/StatusBadge'

interface AutomaticPaymentsContainerProps {
  automaticPaymentsOn: boolean
}
export const AutomaticPaymentsContainer = ({
  automaticPaymentsOn,
}: AutomaticPaymentsContainerProps) => {
  const { t } = useTranslation()

  if (!automaticPaymentsOn) {
    return (
      <>
        <Grid2>
          <StatusBadge
            iconVariant={StatusIconEnum.ALERT}
            label={t('Portal.Home.label.summary.automaticPaymentsOff')}
          />
        </Grid2>
        <Grid2>
          <PortalLink text={t('Portal.Components.label.manage')} onClickHandler={() => {}} />
        </Grid2>
      </>
    )
  }
  return (
    <>
      <Grid2>
        <StatusBadge
          iconVariant={StatusIconEnum.ACTIVE}
          label={t('Portal.Home.label.summary.automaticPaymentsOn')}
        />
      </Grid2>
      <Grid2>
        <PortalLink text={t('Portal.Components.label.viewDetails')} onClickHandler={() => {}} />
      </Grid2>
    </>
  )
}
