import { Navigate, Route, useLocation } from 'react-router-dom'

import BankConnectError from 'src/components/CreditRenewal/BankConnectError'
import CRPaymentProtection from 'src/components/CreditRenewal/CRPaymentProtection'
import ConfirmInformation from 'src/components/CreditRenewal/ConfirmInformation'
import ConnectBankAccount from 'src/components/CreditRenewal/ConnectBankAccount'
import ConnectBankAccountSuccess from 'src/components/CreditRenewal/ConnectBankAccountSuccess'
import CreditRenewalDeclined from 'src/components/CreditRenewal/Declined'
import CreditRenewalEligibility from 'src/components/CreditRenewal/Eligibility'
import CreditRenewalFinish from 'src/components/CreditRenewal/Finish'
import FlinksPaymentCheck from 'src/components/CreditRenewal/FlinksPaymentCheck'
import LoanDetails from 'src/components/CreditRenewal/LoanDetails'
import CreditRenewalReview from 'src/components/CreditRenewal/Review'
import SelectExisting from 'src/components/CreditRenewal/SelectExisting'
import { GenericError } from 'src/components/Error/GenericError'
import PageNotFound from 'src/components/Error/PageNotFound'
import EditPaymentSchedule from 'src/components/PaymentSchedule/EditPaymentSchedule'
import CreditRenewalLayout from 'src/components/common/layouts/CreditRenewal'
import { useCreditRenewalContext } from 'src/hooks'
import useCreditRenewal from 'src/hooks/useCreditRenewal'
import { SentryRoutes } from 'src/routers/common'
import { CreditRenewalStepsEnum } from 'src/types'

export default function AppRouterCreditRenewal() {
  const location = useLocation()
  const { logo, bootstrapIsSuccess, bootstrapIsLoading, bootstrapRefetch } = useCreditRenewal()
  const {
    setStep,
    isCRApplicationSettled,
    cachedApplications,
    applicationId,
    asyncRequestsInProgress,
    bootstrapInfo,
    setCachedApplications,
    setAuthorizePaymentStartError,
  } = useCreditRenewalContext()
  return (
    <SentryRoutes>
      {isCRApplicationSettled ? (
        <Route
          path=":jwtApiKey"
          element={
            <CreditRenewalLayout
              bootstrapIsLoading={bootstrapIsLoading}
              bootstrapIsSuccess={bootstrapIsSuccess}
              logo={logo}
            />
          }
        >
          <Route
            path={CreditRenewalStepsEnum.FINISH}
            element={<CreditRenewalFinish bootstrapRefetch={bootstrapRefetch} />}
          />
          <Route path="*" element={<Navigate to={CreditRenewalStepsEnum.FINISH} />} />
        </Route>
      ) : (
        <Route
          path=":jwtApiKey"
          element={
            <CreditRenewalLayout
              bootstrapIsLoading={bootstrapIsLoading}
              bootstrapIsSuccess={bootstrapIsSuccess}
              logo={logo}
            />
          }
        >
          <Route index element={<Navigate to={CreditRenewalStepsEnum.ELIGIBILITY} />} />
          <Route path={CreditRenewalStepsEnum.ELIGIBILITY} element={<CreditRenewalEligibility />} />
          <Route
            path={CreditRenewalStepsEnum.CONFIRM_INFORMATION}
            element={<ConfirmInformation />}
          />
          <Route
            path={CreditRenewalStepsEnum.REVIEW}
            element={
              <CreditRenewalReview
                nextStep={CreditRenewalStepsEnum.FINISH}
                previousStep={CreditRenewalStepsEnum.PAYMENT_PROTECTION}
              />
            }
          />
          <Route
            path={CreditRenewalStepsEnum.LOAN_DETAILS}
            element={<LoanDetails nextStep={CreditRenewalStepsEnum.PAYMENT_PROTECTION} />}
          />

          <Route
            path={CreditRenewalStepsEnum.REPAYMENT_SCHEDULE}
            element={
              <EditPaymentSchedule
                nextStep={CreditRenewalStepsEnum.LOAN_DETAILS}
                previousStep={CreditRenewalStepsEnum.LOAN_DETAILS}
                applicationId={applicationId}
                cachedApplications={cachedApplications}
                asyncRequestsInProgress={asyncRequestsInProgress}
                setStep={setStep}
                bootstrapInfo={bootstrapInfo}
                setCachedApplications={setCachedApplications}
                setAuthorizePaymentStartError={setAuthorizePaymentStartError}
              />
            }
          />
          <Route
            path={CreditRenewalStepsEnum.PAYMENT_PROTECTION}
            element={
              <CRPaymentProtection
                nextStep={CreditRenewalStepsEnum.REVIEW}
                previousStep={CreditRenewalStepsEnum.LOAN_DETAILS}
              />
            }
          />
          <Route path={CreditRenewalStepsEnum.PAYMENT_METHOD_SELECT} element={<SelectExisting />} />
          <Route path={CreditRenewalStepsEnum.BANK_AUTO_CONNECT} element={<ConnectBankAccount />} />
          <Route path={CreditRenewalStepsEnum.BANK_CONNECT_ERROR} element={<BankConnectError />} />
          <Route
            path={CreditRenewalStepsEnum.BANK_SUCCESS}
            element={<ConnectBankAccountSuccess />}
          />
          <Route
            path={CreditRenewalStepsEnum.FLINKS_PAYMENT_CHECK}
            element={<FlinksPaymentCheck />}
          />
          <Route
            path={CreditRenewalStepsEnum.FINISH}
            element={<CreditRenewalFinish bootstrapRefetch={bootstrapRefetch} />}
          />
          <Route path={CreditRenewalStepsEnum.DECLINED} element={<CreditRenewalDeclined />} />
          <Route path={CreditRenewalStepsEnum.ERROR} element={<GenericError />} />
          <Route path="*" element={<PageNotFound currentLocation={location.pathname} />} />
        </Route>
      )}
    </SentryRoutes>
  )
}
