import { useEffect } from 'react'

import { LoadingAnimation } from 'src/components/common/animations/Loading'
import usePortalContext from 'src/portal/hooks/usePortalContext'

export const Transactions = () => {
  const { loanTransactionHistoryData, portalApiGetLoanTransactionHistoryRefetch } =
    usePortalContext()

  useEffect(() => {
    portalApiGetLoanTransactionHistoryRefetch()
  }, [])

  if (!loanTransactionHistoryData) {
    return (
      <div>
        <LoadingAnimation />
      </div>
    )
  }

  return <div>{JSON.stringify(loanTransactionHistoryData)}</div>
}
