import { Typography } from '@mui/material'
import { t } from 'i18next'

import { useLocalizedFormatters } from 'src/hooks/useLocalizedFormatters'
import { LabelText } from 'src/portal/components/LabelText'
import theme from 'src/themes'

export const UpcomingPaymentAmountContainer = ({
  upcomingPaymentAmount,
}: {
  upcomingPaymentAmount: number
}) => {
  const { currencyFormat } = useLocalizedFormatters()

  return (
    <>
      <LabelText text={t('Portal.Home.label.summary.upcomingPaymentAmount')} />
      <Typography variant="body3" color={theme.color.grey9}>
        {currencyFormat(upcomingPaymentAmount)}
      </Typography>
    </>
  )
}
