import { SignIn, SignedIn, SignedOut } from '@clerk/clerk-react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { Outlet, Route, useLocation } from 'react-router-dom'

import { GenericError } from 'src/components/Error/GenericError'
import MaintenanceMode from 'src/components/Error/MaintenanceMode'
import PageNotFound from 'src/components/Error/PageNotFound'
import RootLayout from 'src/components/common/RootLayout'
import { logoImgSvg } from 'src/images'
import { PortalRoutesEnum } from 'src/portal/common'
import PortalIndex from 'src/portal/index'
import Layout from 'src/portal/layout'
import PortalProfileIndex from 'src/portal/profile/index'
import { SentryRoutes } from 'src/routers/common'
import { FeatureFlags } from 'src/types'

const PortalRouteWrapper = () => {
  const { isMaintenance } = useFlags<FeatureFlags>()

  if (isMaintenance) {
    return (
      <RootLayout logo={{ src: logoImgSvg, alt: 'Fig logo' }}>
        <MaintenanceMode />
      </RootLayout>
    )
  }

  return (
    <>
      <SignedOut>
        <SignIn />
      </SignedOut>
      <SignedIn>
        <Layout>
          <Outlet />
        </Layout>
      </SignedIn>
    </>
  )
}

export default function PortalRouter() {
  const location = useLocation()

  return (
    <SentryRoutes>
      <Route element={<PortalRouteWrapper />}>
        <Route index element={<PortalIndex />} />
        <Route path={PortalRoutesEnum.PORTAL_PROFILE} element={<PortalProfileIndex />} />

        <Route
          path={PortalRoutesEnum.PORTAL_ERROR}
          element={
            <RootLayout logo={{ src: logoImgSvg, alt: 'Fig logo' }}>
              <GenericError isUncaughtError={false} />
            </RootLayout>
          }
        />
        <Route
          path="*"
          element={
            <RootLayout logo={{ src: logoImgSvg, alt: 'Fig logo' }}>
              <PageNotFound currentLocation={location.pathname} />
            </RootLayout>
          }
        />
      </Route>
    </SentryRoutes>
  )
}
