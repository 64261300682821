import { PortalRoutesEnum } from 'src/portal/common'

export interface MenuButtonItem {
  icon: string
  iconActive?: string
  iconAlt: string
  label: string
  isHidden?: boolean
  route?: PortalRoutesEnum
  externalLink?: string
  onClickHandler?: () => void
}

export interface DesktopMenuItemProps {
  item: MenuButtonItem
  index: number
  navigate: (route: string) => void
  isActive: boolean
  hasNotification: boolean
}

export const handleNavigation = (item: MenuButtonItem, navigate: any) => {
  if (item.externalLink) {
    window.open(item.externalLink, '_blank')
  } else {
    navigate(item.route!)
  }
}
