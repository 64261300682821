import { Grid2 } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { questionCircleIcon } from 'src/images'
import { LoanStatusEnum } from 'src/portal/common'
import { TooltipContainer } from 'src/portal/components/tooltips/TooltipContainer'
import { LoanStatusLabel } from 'src/portal/pages/home/summary/content/LoanStatusLabel'
import { LoanStatusContent } from 'src/portal/pages/home/summary/content/TooltipContent'

interface LoanStatusContainerProps {
  loanStatus: LoanStatusEnum
}

export const LoanStatusContainer: React.FC<LoanStatusContainerProps> = ({ loanStatus }) => {
  const { t } = useTranslation()
  return (
    <Grid2>
      <LoanStatusLabel loanStatus={loanStatus} />

      <TooltipContainer
        iconSrc={questionCircleIcon}
        iconAlt={t('Portal.Components.alt.questionCircleIcon')}
        title={t('Portal.Components.tooltip.title.accountStatus')}
        content={<LoanStatusContent />}
      />
    </Grid2>
  )
}
