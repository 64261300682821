import { Grid2, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { useLocalizedFormatters } from 'src/hooks/useLocalizedFormatters'
import { infoCircleIcon } from 'src/images'
import { TooltipContainer } from 'src/portal/components/tooltips/TooltipContainer'
import { RemainingBalanceContent } from 'src/portal/pages/home/summary/content/TooltipContent'

interface TotalAmountContainerProps {
  totalAmount: number
}

export const TotalAmountContainer: React.FC<TotalAmountContainerProps> = ({ totalAmount }) => {
  const { t } = useTranslation()
  const { currencyFormat } = useLocalizedFormatters()

  return (
    <Grid2 display="flex" alignItems="center">
      <Typography variant="h1">{currencyFormat(totalAmount)}</Typography>
      <TooltipContainer
        iconSrc={infoCircleIcon}
        iconAlt={t('Portal.Components.alt.infoCircleIcon')}
        title={t('Portal.Components.tooltip.title.remainingBalance')}
        content={<RemainingBalanceContent />}
      />
    </Grid2>
  )
}
