import { Typography } from '@mui/material'

import theme from 'src/themes'

export const LabelText = ({ text }: { text: string }) => {
  return (
    <Typography variant="body3" color={theme.color.grey7} py="6px">
      {text}
    </Typography>
  )
}
