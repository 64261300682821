import { Typography } from '@mui/material'
import Grid from '@mui/material/Grid2'
import { t } from 'i18next'
import { Trans } from 'react-i18next'

import { useConfiguration } from 'src/hooks'
import { useLocalizedFormatters } from 'src/hooks/useLocalizedFormatters'
import { SectionContainer } from 'src/components/EndToEnd/landing/Walmart/components/SectionContainer'

export const TermsSection = () => {
  const walmartDeductValue = 0.12
  const { percentFormat, currencyFormat } = useLocalizedFormatters()
  const { loanLimits } = useConfiguration()

  const disclaimerValues = {
    min_apr: percentFormat(loanLimits?.minApr, 2),
    max_apr: percentFormat(loanLimits?.maxApr ? loanLimits?.maxApr - walmartDeductValue : 0, 2),
    min_amt: currencyFormat(loanLimits?.minLoanAmount, 0),
    max_amt: currencyFormat(loanLimits?.maxLoanAmount, 0),
  }

  return (
    <SectionContainer>
      <Grid>
        <Typography component="p" variant="termsText" marginBottom="16px">
          {t('e2e.Landing.walmart.termsAndConditions.figDisclaimer', disclaimerValues)}
        </Typography>
        <Typography component="p" variant="termsText" marginBottom="16px">
          {t('e2e.Landing.walmart.termsAndConditions.giftCardDisclaimer', disclaimerValues)}
        </Typography>
        <Typography component="p" variant="termsText" marginBottom="16px">
          <Trans
            i18nKey="e2e.Landing.walmart.termsAndConditions.cashContestDisclaimer_part1"
            components={{ sup: <sup /> }}
          ></Trans>
        </Typography>
        <Typography component="p" variant="termsText">
          <Trans
            i18nKey="e2e.Landing.walmart.termsAndConditions.cashContestDisclaimer_part2"
            components={{
              sup: <sup />,
              pdfLink: (
                <a
                  href={t('e2e.Landing.cashContestLandingPromo.cash_contest_10K_rules_pdf')}
                  target="_blank"
                  rel="noreferrer"
                >
                  See full contest rules and regulations.
                </a>
              ),
            }}
          ></Trans>
        </Typography>
      </Grid>
    </SectionContainer>
  )
}
