import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { notificationCircleIconRed } from 'src/images'
import { PortalRoutesEnum } from 'src/portal/common'
import theme from 'src/themes'

interface MenuButtonProps {
  iconActive: string
  icon: string
  iconAlt: string
  label: string
  isActive?: boolean
  hasNotification?: boolean
  route?: PortalRoutesEnum
  onClick?: () => void
}

export const MobileMenuButton = ({
  icon,
  iconActive,
  iconAlt,
  label,
  isActive,
  hasNotification,
  route,
  onClick,
}: MenuButtonProps) => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const handleClick = () => {
    if (onClick) {
      onClick() //used for modal open / close in mobile menu
    } else if (route) {
      navigate(route)
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        flex: 1,
        color: isActive ? theme.color.brand1 : theme.color.grey10,
        cursor: 'pointer',
        position: 'relative',
      }}
      onClick={handleClick}
    >
      <Box sx={{ position: 'relative' }}>
        <img src={isActive ? iconActive : icon} alt={iconAlt} />
        {hasNotification && (
          <img
            src={notificationCircleIconRed}
            style={{
              position: 'absolute',
              top: '-4px',
              right: '-4px',
              width: '8px',
              height: '8px',
            }}
            alt={t('Portal.Menu.alt.notificationIcon')}
          />
        )}
      </Box>
      <Typography
        variant="body2"
        color={isActive ? theme.color.brand1 : theme.color.grey10}
        sx={{ mt: 0.5, fontWeight: isActive ? 500 : 400 }}
      >
        {label}
      </Typography>
    </Box>
  )
}
