import { Box, Grid2 } from '@mui/material'
import { ReactNode } from 'react'

import theme from 'src/themes'

export const DesktopMenuContainer = ({ children }: { children: ReactNode }) => {
  return (
    <Box
      sx={{
        maxWidth: '390px',
        height: '100vh',
        paddingTop: '32px',
        backgroundColor: theme.color.white,
        boxShadow: `0px 4px 16px 0px #00000014`,
        borderLeft: `1px solid ${theme.color.grey4}`,
        overflow: 'hidden', // Prevents content from spilling
      }}
    >
      <Grid2 container direction="column" sx={{ height: '100%' }}>
        {children}
      </Grid2>
    </Box>
  )
}
