import { Box, Grid2 } from '@mui/material'
import { ReactNode } from 'react'

import theme from 'src/themes'

export const MobileMenuContainer = ({ children }: { children: ReactNode }) => {
  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        borderTop: `1px solid ${theme.color.grey3}`,
        borderBottom: `1px solid ${theme.color.grey3}`,
        backgroundColor: theme.color.white,
        padding: '16px 0 24px 0',
      }}
    >
      <Grid2 container justifyContent="space-around" alignItems="center">
        {children}
      </Grid2>
    </Box>
  )
}
