import { Typography } from '@mui/material'
import { Link } from 'react-router-dom'

import theme from 'src/themes'

// TODO: translations
export const LoanStatusContent = () => {
  return (
    <>
      <Typography variant="body1" color={theme.color.grey7} sx={{ mb: 2 }}>
        Your account status will show either:
      </Typography>
      <Typography variant="body1" color={theme.color.grey9} sx={{ fontWeight: 'bold' }}>
        Good standing
      </Typography>
      <Typography variant="body2" color={theme.color.grey7} sx={{ mb: 2 }}>
        All expected payments have been made as scheduled
      </Typography>
      <Typography variant="body1" color={theme.color.grey9} sx={{ fontWeight: 'bold' }}>
        Payment overdue
      </Typography>
      <Typography variant="body2" color={theme.color.grey7} sx={{ mb: 2 }}>
        One or more expected payments have been missed
      </Typography>
      <Typography variant="body1" color={theme.color.grey9} sx={{ fontWeight: 'bold' }}>
        Closed
      </Typography>
      <Typography variant="body2" color={theme.color.grey7} sx={{ mb: 2 }}>
        Your loan has been paid off
      </Typography>
      <Typography variant="body2" color={theme.color.grey7} sx={{ mb: 4 }}>
        If you see a "Your payment is overdue" message, please pay your outstanding amount as soon
        as possible or contact our support team to arrange for a payment to return your account to
        good standing.
      </Typography>
      <Link
        to={'#'}
        color={theme.color.brand1}
        style={{ fontWeight: 'bold', display: 'block', textAlign: 'center' }}
      >
        Learn more
      </Link>
    </>
  )
}

export const RemainingBalanceContent = () => {
  return (
    <>
      <Typography variant="body1" color={theme.color.grey7} sx={{ mb: 2 }}>
        This is the total outstanding balance including principal, interest, and any applicable
        fees.
      </Typography>
      <Typography variant="body1" color={theme.color.grey9} sx={{ fontWeight: 'bold' }}>
        Principal Amount
      </Typography>
      <Typography variant="body2" color={theme.color.grey7} sx={{ mb: 2 }}>
        The original amount of the loan before any interest or fees.
      </Typography>
      <Typography variant="body1" color={theme.color.grey9} sx={{ fontWeight: 'bold' }}>
        Interest
      </Typography>
      <Typography variant="body2" color={theme.color.grey7} sx={{ mb: 2 }}>
        The amount charged for borrowing the principal amount.
      </Typography>
      <Typography variant="body1" color={theme.color.grey9} sx={{ fontWeight: 'bold' }}>
        Fees
      </Typography>
      <Typography variant="body2" color={theme.color.grey7} sx={{ mb: 2 }}>
        Any additional fees included in your total balance.
      </Typography>
    </>
  )
}

export const LoanIdContent = () => (
  <>
    <Typography variant="body1" color={theme.color.grey7} sx={{ mb: 2 }}>
      Use this number as your account number when you try to make a bill payment through your bank.
    </Typography>
    <Link
      to={'#'}
      color={theme.color.brand1}
      style={{ fontWeight: 'bold', display: 'block', textAlign: 'center' }}
    >
      Learn more
    </Link>
  </>
)
export const LoanTermContent = () => (
  <>
    {/* TODO: contact us link in bold */}
    <Typography variant="body1" color={theme.color.grey7} sx={{ mb: 2 }}>
      Please contact us if you would like to request a term change. If you make a lump sum payment
      or have a repayment schedule more frequent than once a month, your actual repayment term may
      be shorter than originally planned. However, if you miss or delay any payments, this may
      extend your repayment term beyond the initially scheduled end date
    </Typography>
  </>
)
