import { Box } from '@mui/material'
import React from 'react'

interface DetailsIconProps {
  src: string
  alt: string
  onClickHandler?: (event: React.MouseEvent<HTMLElement>) => void
}
/**
 * A functional component that renders an icon image with specified source, alt text, and click handler.
 *
 * @param {string} src - The source URL of the image.
 * @param {string} alt - The alt text for the image.
 * @param {() => void} onClickHandler - The function to call when the image is clicked.
 * @returns {JSX.Element} The rendered image element.
 */
export const DetailsIcon: React.FC<DetailsIconProps> = ({ src, alt, onClickHandler }) => {
  return (
    <Box display="inline-block" ml={'4px'}>
      <img
        src={src}
        alt={alt}
        style={{ verticalAlign: 'middle' }}
        width="16px"
        height="16px"
        onClick={onClickHandler}
      />
    </Box>
  )
}
