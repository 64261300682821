import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { portalMenudivider } from 'src/images'

export const MenuDivider = () => {
  const { t } = useTranslation()
  return (
    <Box style={{ display: 'flex', justifyContent: 'center' }}>
      <img
        src={portalMenudivider}
        style={{ padding: '10px 32px', maxWidth: '80%' }}
        alt={t('Portal.Menu.alt.dividerIcon')}
      />
    </Box>
  )
}
