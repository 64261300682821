import { Typography } from '@mui/material'
import { t } from 'i18next'

import { useLocalizedFormatters } from 'src/hooks/useLocalizedFormatters'
import { LabelText } from 'src/portal/components/LabelText'
import theme from 'src/themes'

export const UpcomingPaymentDateContainer = ({
  upcomingPaymentDate,
}: {
  upcomingPaymentDate: string
}) => {
  const { dateFormatCustom } = useLocalizedFormatters()

  return (
    <>
      <LabelText text={t('Portal.Home.label.summary.upcomingPaymentDate')} />
      <Typography variant="body3" color={theme.color.grey9}>
        {dateFormatCustom(new Date(upcomingPaymentDate), t('Portal.Home.label.summary.dateFormat'))}
      </Typography>
    </>
  )
}
