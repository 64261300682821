import { useState } from 'react'

import { LoanStatusEnum } from 'src/portal/common'
import { RoundedBox } from 'src/portal/components/RoundedBox'
import { SpacedRow } from 'src/portal/components/SpacedRow'
import { StretchDivider } from 'src/portal/components/StretchDivider'
import { PayNowButton } from 'src/portal/pages/home/components/PayNowButton'
import { ShowDetailsButton } from 'src/portal/pages/home/components/ShowDetailsButton'
import { AutomaticPaymentsContainer } from 'src/portal/pages/home/summary/containers/AutomaticPaymentsContainer'
import { LoanDetailsContainer } from 'src/portal/pages/home/summary/containers/LoanDetailsContainer'
import { LoanStatusContainer } from 'src/portal/pages/home/summary/containers/LoanStatusContainer'
import { PaymentProtectionContainer } from 'src/portal/pages/home/summary/containers/PaymentProtectionContainer'
import { TotalAmountContainer } from 'src/portal/pages/home/summary/containers/TotalAmountContainer'
import { UpcomingPaymentAmountContainer } from 'src/portal/pages/home/summary/containers/UpcomingPaymentAmountContainer'
import { UpcomingPaymentDateContainer } from 'src/portal/pages/home/summary/containers/UpcomingPaymentDateContainer'
import { RemainingBalanceTitle, SummaryTitle } from 'src/portal/pages/home/summary/content/Titles'

export interface SummaryProps {
  totalAmount: number
  loanStatus: LoanStatusEnum
  automaticPaymentsOn: boolean
  upcomingPaymentAmount: number
  upcomingPaymentDate: string
  paymentProtectionActive: boolean
  loanId: string
  loanAmount: number
  interestRate: number
  loanTerm: number
}

const Summary = ({
  totalAmount,
  loanStatus,
  automaticPaymentsOn,
  upcomingPaymentAmount,
  upcomingPaymentDate,
  paymentProtectionActive,
  loanId,
  loanAmount,
  interestRate,
  loanTerm,
}: SummaryProps) => {
  const [loanDetailsExpanded, setLoanDetailsExpanded] = useState(false)
  return (
    <RoundedBox>
      <SpacedRow>
        <SummaryTitle />
        <TotalAmountContainer totalAmount={totalAmount} />
      </SpacedRow>

      <SpacedRow>
        <LoanStatusContainer loanStatus={loanStatus} />
        <RemainingBalanceTitle />
      </SpacedRow>

      <StretchDivider />

      <SpacedRow>
        <UpcomingPaymentAmountContainer upcomingPaymentAmount={upcomingPaymentAmount} />
      </SpacedRow>

      <SpacedRow>
        <UpcomingPaymentDateContainer upcomingPaymentDate={upcomingPaymentDate} />
      </SpacedRow>

      <SpacedRow>
        <AutomaticPaymentsContainer automaticPaymentsOn={automaticPaymentsOn} />
      </SpacedRow>

      <SpacedRow>
        <PaymentProtectionContainer paymentProtectionActive={paymentProtectionActive} />
      </SpacedRow>

      {!automaticPaymentsOn && <PayNowButton onClickHandler={() => {}} />}

      {loanDetailsExpanded && (
        <>
          <StretchDivider />
          <LoanDetailsContainer
            loanId={loanId}
            loanAmount={loanAmount}
            interestRate={interestRate}
            loanTerm={loanTerm}
          />
        </>
      )}

      <ShowDetailsButton onClickHandler={() => setLoanDetailsExpanded(!loanDetailsExpanded)} />
    </RoundedBox>
  )
}
export default Summary
