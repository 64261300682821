import { useEffect } from 'react'

import { LoadingAnimation } from 'src/components/common/animations/Loading'
import usePortalContext from 'src/portal/hooks/usePortalContext'

export const UpcomingPayments = () => {
  const { loanRepaymentScheduleData, portalApiGetLoanRepaymentScheduleRefetch } = usePortalContext()

  useEffect(() => {
    portalApiGetLoanRepaymentScheduleRefetch()
  }, [])

  if (!loanRepaymentScheduleData) {
    return (
      <div>
        <LoadingAnimation />
      </div>
    )
  }

  return <div>{JSON.stringify(loanRepaymentScheduleData)}</div>
}
