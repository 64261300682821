import { useMediaQuery } from '@mui/material'
import React, { ReactNode } from 'react'

import { DesktopTooltip } from 'src/portal/components/tooltips/DesktopTooltip'
import { MobileTooltip } from 'src/portal/components/tooltips/MobileTooltip'
import theme from 'src/themes'

interface ResponsiveTooltipProps {
  isOpen: boolean
  anchorEl: HTMLElement | null
  onClose: () => void
  title: string
  content: ReactNode
}
/**
 * ResponsiveTooltip component that conditionally renders either a MobileTooltip or DesktopTooltip
 * based on the screen size. This is part of a building block process for TooltipContainer to
 * separate desktop and mobile displays.
 *
 * @param {ResponsiveTooltipProps} props - The properties passed to the ResponsiveTooltip component.
 * @param {boolean} props.isOpen - Determines if the tooltip is open.
 * @param {HTMLElement} [props.anchorEl] - The element to which the tooltip is anchored (for desktop).
 * @param {() => void} props.onClose - Function to call when the tooltip should be closed.
 * @param {string} props.title - The title of the tooltip.
 * @param {string} props.content - The content of the tooltip.
 *
 * @returns {JSX.Element} The rendered tooltip component, either for mobile or desktop.
 */
export const ResponsiveTooltip: React.FC<ResponsiveTooltipProps> = ({
  isOpen,
  anchorEl,
  onClose,
  title,
  content,
}) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  if (isMobile) {
    return <MobileTooltip isOpen={isOpen} onClose={onClose} title={title} content={content} />
  }

  return (
    <DesktopTooltip
      isOpen={isOpen}
      anchorEl={anchorEl}
      onClose={onClose}
      title={title}
      content={content}
    />
  )
}
