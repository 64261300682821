import { Grid2 } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { PortalLink } from 'src/portal/components/PortalLink'
import { StatusIconEnum } from 'src/portal/components/types'
import { StatusBadge } from 'src/portal/pages/home/components/StatusBadge'

interface PaymentProtectionContainerProps {
  paymentProtectionActive: boolean
}
export const PaymentProtectionContainer = ({
  paymentProtectionActive,
}: PaymentProtectionContainerProps) => {
  const { t } = useTranslation()

  if (!paymentProtectionActive) {
    return null
  }

  return (
    <>
      <Grid2>
        <StatusBadge
          iconVariant={StatusIconEnum.ACTIVE}
          label={t('Portal.Home.label.summary.paymentProtectionActive')}
        />
      </Grid2>
      <Grid2>
        <PortalLink text={t('Portal.Components.label.viewDetails')} onClickHandler={() => {}} />
      </Grid2>
    </>
  )
}
