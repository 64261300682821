const portalTranslation = {
  Portal: {
    Header: {
      alt: {
        figLogo: 'Fig logo',
        userIcon: 'User icon',
        languageDropdown: 'Toggle dropdown',
      },
      label: {
        languageDropdown: 'Language',
        profileSettings: 'Profile settings',
        logout: 'Log out',
      },
    },
    Menu: {
      links: {
        referral: `https://fig.ca/en/referral/`,
        terms: `https://fig.ca/en/legal/terms-of-service/`,
        privacy: `https://fig.ca/en/legal/privacy-statement/`,
        helpCenter: `https://support.fig.ca/en/support/home`,
      },
      alt: {
        homeIcon: 'Home icon',
        paymentsIcon: 'Payments icon',
        giftIcon: 'Gift icon',
        documentsIcon: 'Documents icon',
        moreIcon: 'More icon',
        paymentProtectionIcon: 'Payment protection icon',
        loanIncreaseIcon: 'Loan increase icon',
        helpCenterIcon: 'Help center icon',
        termsIcon: 'Terms of use icon',
        notificationIcon: 'Notification icon',
        dividerIcon: 'Menu divider',
      },
      label: {
        home: 'Home',
        payments: 'Payments',
        documents: 'Documents',
        managePayments: 'Manage payments',
        viewDocuments: 'View documents',
        more: 'More',
        paymentProtection: 'Payment protection',
        loanIncrease: 'Request a loan amount increase',
        helpCenter: 'Help center',
        terms: 'Terms of use',
      },
    },
    Home: {
      label: {
        tab: {
          summary: 'Summary',
          transactions: 'Transactions',
          upcomingPayments: 'Upcoming Payments',
        },
        summary: {
          title: 'Summary',
          loanStatus: {
            good: 'Good standing',
            overdue: 'Payment overdue',
            closed: 'Closed',
          },
          remainingBalance: 'Remaining balance',
          upcomingPaymentAmount: 'Upcoming payment amount',
          upcomingPaymentDate: 'Upcoming payment date',
          dateFormat: 'MMM d, yyyy',
          automaticPaymentsOn: 'Automatic payments: On',
          automaticPaymentsOff: 'Automatic payments: Off',
          paymentProtectionActive: 'Payment protection: Active',
          loanDetails: 'Loan details',
          loanId: 'Loan ID',
          loanAmount: 'Loan amount',
          interestRate: 'Interest rate',
          loanTerm: 'Loan term',
          loanTermInMonths: '{{count}} months',
        },
        greeting: {
          morning: 'Good morning',
          afternoon: 'Good afternoon',
          evening: 'Good evening',
        },
      },
    },
    Components: {
      alt: {
        infoCircleIcon: 'Information icon',
        questionCircleIcon: 'Question mark icon',
        statusIcon: 'Status icon',
        chevronIcon: 'Chevron icon',
      },
      label: {
        viewDetails: 'View details',
        manage: 'Manage',
      },
      tooltip: {
        title: {
          accountStatus: 'Account status',
          remainingBalance: 'Remaining balance',
          loanId: 'Loan ID',
          loanTerm: 'Loan term',
        },
      },
    },
  },
}
export default portalTranslation
