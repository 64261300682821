import { Grid2 } from '@mui/material'

import { SecondaryCTA } from 'src/components/common/Buttons'
import { chevronDownicon } from 'src/images'
import theme from 'src/themes'

interface ShowDetailsButtonProps {
  onClickHandler: () => void
}
/**
 * ShowDetailsButton component renders a button that displays "Show details" with a chevron icon.
 * The button is styled to be displayed at the bottom of a container.
 *
 * @param {function} onClickHandler - The function to be called when the button is clicked.
 * @returns {JSX.Element} The rendered ShowDetailsButton component.
 */
export const ShowDetailsButton = ({ onClickHandler }: ShowDetailsButtonProps) => {
  return (
    <Grid2
      sx={{
        transform: 'translateY(103%)',
        maxWidth: '178px',
        margin: '0 auto',
        background: theme.color.white,
      }}
      onClick={onClickHandler}
    >
      <SecondaryCTA
        buttonText="Show details"
        sx={{
          display: 'inline-block',
          px: '24px',
          py: '8px',
        }}
        endIcon={
          <img src={chevronDownicon} alt="chevronDownicon" style={{ verticalAlign: 'middle' }} />
        }
      />
    </Grid2>
  )
}
