import { Box } from '@mui/material'

import theme from 'src/themes'

interface RoundedBoxProps {
  children: React.ReactNode
}

export const RoundedBox: React.FC<RoundedBoxProps> = ({ children }) => {
  return (
    <Box
      sx={{
        paddingY: '24px',
        paddingX: '16px',
        backgroundColor: theme.color.white,
        borderRadius: '8px',
        border: `1px solid ${theme.color.grey3}`,
      }}
    >
      {children}
    </Box>
  )
}
