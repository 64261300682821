import { useTranslation } from 'react-i18next'

import { PaymentScheduleCalendar } from 'src/components/PaymentSchedule/shared/PaymentScheduleCalendar'
import { StepsEnum, CreditRenewalStepsEnum, SavedRepaymentScheduleType } from 'src/types'

interface AsyncRequestsInProgress {
  getRepaymentSchedule: boolean
  saveOffer: boolean
}

export function PaymentCalendarComponent({
  asyncRequestsInProgress,
  cachedSchedule,
  setStep,
}: {
  asyncRequestsInProgress: AsyncRequestsInProgress
  cachedSchedule: SavedRepaymentScheduleType | null | undefined
  setStep: (step: StepsEnum | CreditRenewalStepsEnum) => void
}) {
  const { t } = useTranslation()

  return (
    <PaymentScheduleCalendar
      isLoading={
        asyncRequestsInProgress.getRepaymentSchedule ||
        asyncRequestsInProgress.saveOffer ||
        !cachedSchedule
      }
      showChangeButton={true}
      showDisclaimer={false}
      first_payment_date={cachedSchedule?.first_payment_date}
      payment_cycle_due_date={cachedSchedule?.payment_cycle_due_date}
      schedule={cachedSchedule?.schedule}
      monthly_choice={cachedSchedule?.monthly_choice}
      withdraw_amount={cachedSchedule?.withdraw_amount}
      nextStep={CreditRenewalStepsEnum.REPAYMENT_SCHEDULE}
      aria-label={t('PaymentSchedule.PaymentScheduleCalendar.changeScheduleLabel')}
      setStep={setStep}
    />
  )
}
