import enPortalTranslation from 'src/locales/en/portalTranslation'
const portalTranslation: typeof enPortalTranslation = {
  Portal: {
    Header: {
      alt: {
        figLogo: '',
        userIcon: '',
        languageDropdown: '',
      },
      label: {
        languageDropdown: '',
        profileSettings: '',
        logout: '',
      },
    },
    Menu: {
      links: {
        referral: `https://fig.ca/fr/referral/`,
        terms: `https://fig.ca/fr/legal/terms-of-service/`,
        privacy: `https://fig.ca/fr/legal/privacy-statement/`,
        helpCenter: `https://support.fig.ca/fr/support/home`,
      },
      alt: {
        homeIcon: '',
        paymentsIcon: '',
        giftIcon: '',
        documentsIcon: '',
        moreIcon: '',
        paymentProtectionIcon: '',
        loanIncreaseIcon: '',
        helpCenterIcon: '',
        termsIcon: '',
        notificationIcon: '',
        dividerIcon: '',
      },
      label: {
        home: '',
        payments: '',
        documents: '',
        managePayments: '',
        viewDocuments: '',
        more: '',
        paymentProtection: '',
        loanIncrease: '',
        helpCenter: '',
        terms: '',
      },
    },
    Home: {
      label: {
        tab: {
          summary: '',
          transactions: '',
          upcomingPayments: '',
        },
        summary: {
          title: '',
          loanStatus: {
            good: '',
            overdue: '',
            closed: '',
          },
          remainingBalance: '',
          upcomingPaymentAmount: '',
          upcomingPaymentDate: '',
          dateFormat: '',
          automaticPaymentsOn: '',
          automaticPaymentsOff: '',
          paymentProtectionActive: '',
          loanDetails: '',
          loanId: '',
          loanAmount: '',
          interestRate: '',
          loanTerm: '',
          loanTermInMonths: '',
        },
        greeting: {
          morning: '',
          afternoon: '',
          evening: '',
        },
      },
    },
    Components: {
      alt: {
        infoCircleIcon: '',
        questionCircleIcon: '',
        statusIcon: '',
        chevronIcon: '',
      },
      label: {
        viewDetails: '',
        manage: '',
      },
      tooltip: {
        title: {
          accountStatus: '',
          remainingBalance: '',
          loanId: '',
          loanTerm: '',
        },
      },
    },
  },
}
export default portalTranslation
